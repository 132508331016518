// Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThreads, faFacebook, faInstagram, faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons';
import './footer.css'; // Import the footer styles

function Footer({ didHideFooter = false }) {

    if (didHideFooter === true) {
        console.log('hiding footer');
        return;
    }

    return (
        <footer className="footer">
            <div className="footer-content">
                <a href='mailto:askeva@yaswedo.com' className="contact-link">
                    <h3>Contact Us</h3>
                </a>

                {/* Social Media and App Links */}
                <div className="links-section">
                    {/* Social Media Section */}
                    <div className="social-section">
                        <h3>Follow AskEVA on Social Media</h3>
                        {/* Social Media Icons */}
                        <div className="social-icons">
                            <a href="https://www.facebook.com/profile.php?id=100091795845436" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} size="2x" />
                            </a>
                            <a href="https://www.instagram.com/askeva.ai/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} size="2x" />
                            </a>
                            <a href="https://www.threads.net/@askeva.ai" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faThreads} size="2x" />
                            </a>
                            {/* Add more social media icons as needed */}
                        </div>
                    </div>

                    {/* App Links Section */}
                    <div className="app-section">
                        <h3>Download the AskEVA App</h3>
                        {/* App Links */}
                        <div className="app-links">
                            <a href="https://apps.apple.com/us/app/ask-eva/id6456941841" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faAppStore} size="2x" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.tambool.askEVA" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faGooglePlay} size="2x" />
                            </a>
                            {/* Add more app icons or links as needed */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;