import React from 'react';

import Modal from 'react-modal';
import GoogleButton from 'react-google-button'

import './LoginPopup.css';

Modal.setAppElement('#root');

function LoginPopup(props) {
  function handleLoginWithGoogle() {
    // TODO: Implement login with Google logic
    props.onLoginWithGoogle();
    props.onClose();
  }

  function handleContinueAnonymously() {
    // TODO: Implement continue anonymously logic
    props.onLoginAnon();
    props.onClose();
  }

  function handleClose() {
    props.onClose();
  }

  const googleButtonStyle = {
    backgroundColor: 'transparent',
    border: '1px solid black',
    borderRadius: '5px',
    fontSize: '16px',
    color: 'black',
    cursor: 'pointer',
    boxShadow: 'none'
  };

  return (
    <Modal
      isOpen={props.showLoginPopup}
      onRequestClose={props.onClose}
      contentLabel="Login Popup"
      className="login-popup"
      overlayClassName="login-popup-overlay"
    >
      <h2>Log in to AskEVA</h2>
      <div className="login-buttons">
        <GoogleButton style={googleButtonStyle} type="light" onClick={handleLoginWithGoogle}>Continue with Google</GoogleButton>
        {/* <button onClick={handleLoginWithGoogle}>Login with Google</button> */}
        <p>-----OR-----</p>
        <button onClick={handleContinueAnonymously}>Continue Anonymously</button>
        <br/>
        <button className='modal-close-button' onClick={props.onClose}>Cancel</button>
      </div>
    </Modal>
  );
}

export default LoginPopup;
