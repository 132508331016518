import React, { useState, useEffect } from 'react';
import "../css/journal.css";

import firebase from '../firebase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import { generatePDF } from '../utilities/pdf'

import JournalGraph from '../Components/Graphs/JournalGraph';
import { BodyComponent } from "reactjs-human-body";

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import Footer from '../Components/Footer/Footer';

const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics();

export default function Journal() {
    const [entries, setEntries] = useState([]);
    const [selectedEntryId, setSelectedEntryId] = useState(null);

    const [userProfile, setUserProfile] = useState({});
    const [bodyState, setBodyState] = useState({
        head: {
            show: true,
            selected: false
        },
        left_shoulder: {
            show: true,
            selected: false
        },
        right_shoulder: {
            show: true,
            selected: false
        },
        left_arm: {
            show: true,
            selected: false
        },
        right_arm: {
            show: true,
            selected: false
        },
        chest: {
            show: true,
            selected: false
        },
        stomach: {
            show: true,
            selected: false
        },
        left_leg: {
            show: true,
            selected: false
        },
        right_leg: {
            show: true,
            selected: false
        },
        left_hand: {
            show: true,
            selected: false
        },
        right_hand: {
            show: true,
            selected: false
        },
        left_foot: {
            show: true,
            selected: false
        },
        right_foot: {
            show: true,
            selected: false
        }
    });

    const { uid } = auth.currentUser;
    const journalQuery = firestore.collection('journal').where('uid', '==', uid);
    const userQuery = firestore.collection('users').where('uid', '==', uid).limit(1);

    useEffect(() => {
        console.log('render');
        analytics.logEvent('View_Journal');
        getJounalEntries();
        getBodyState();
    }, []);

    function handleEntryOpen(id) {
        setSelectedEntryId(id);
    }

    const handleClickBody = async (id) => {
        // Handle the click event here
        console.log(`id: ${id}`, `bodyState: ${JSON.stringify(bodyState)}`);
        analytics.logEvent('Interact_Body');

        //update firebase
        await userQuery.get().then(async (snapshot) => {
            if (snapshot.size == 1) {
                console.log(snapshot.docs[0].data(), bodyState);
                await snapshot.docs[0].ref.update({
                    bodyState
                })
            } else {
                console.log('something weird happend')
            }
        })
    };

    function deleteEntry(id) {
        console.log('function delete: ', id)
        firestore.collection('journal').doc(id).delete().then((snapshot) => {
            console.log('entry deleted: ', snapshot);
            analytics.logEvent('Journal_Entry_Deleted');
            setEntries(entries.filter(entry => entry.session !== id))
        }).catch((error) => {
            console.log(error);
        })
    }

    function handleExport() {
        // Generate PDF from entries
        let doc = generatePDF(entries, userProfile);
        // Save the PDF
        doc.save("journal_entries.pdf");
    }

    function handleEntryClose() {
        setSelectedEntryId(null);
    }

    function getBodyState() {
        console.log('getting body')
        return userQuery.get().then(snapshot => {
            if (snapshot.size === 1) {
                let userData = snapshot.docs[0].data();
                const storedBodyState = userData.bodyState;
                setUserProfile(userData);
                if (storedBodyState && storedBodyState !== {}) {
                    setBodyState(storedBodyState)
                    console.log('setbodystate', storedBodyState)
                }
            }
        })
    }

    function getJounalEntries() {
        let journalEntries = [];

        return journalQuery.orderBy('createdAt', 'desc').get()
            .then((snapshot) => {
                if (!snapshot.empty) {
                    snapshot.docs.forEach(doc => {
                        const ROS = doc.data().ROS;
                        const createdAt = doc.data().createdAt.seconds;
                        const session = doc.data().session;

                        journalEntries.push({ ROS, createdAt, session });
                    })
                }

                //console.log('Journal Entries: ', journalEntries);
                setEntries(journalEntries);
            }).catch((error) => {
                console.log(error);
            })
    }

    return (
        <>
            <div>
                {entries.length === 0 ? (
                    <div className="no-entries">
                        No journal entries created. Talk to EVA to create a new journal entry.
                    </div>
                ) : (
                    <>
                        <br /><br /><br /><br /><br /><br /><br />
                        <div className='toolbar'>
                            <div className="toolbar-label">EVA's Journal</div>
                            <button className="toolbar button" onClick={handleExport} title="Download Journal">
                                <FontAwesomeIcon icon={faDownload} color='black' />
                            </button>
                        </div>

                        <div className="container">
                            {/* <div className="graph-container">
                            <JournalGraph entries={entries} />
                        </div> */}
                            <div className="body-container">
                                <BodyComponent
                                    partsInput={bodyState}
                                    onClick={handleClickBody}
                                />
                            </div>
                        </div>

                        <div id='journal-entries' className="entry-feed-container">
                            <div className="entry-feed">
                                {entries.map((entry) => (
                                    <Entry
                                        key={entry.session}
                                        id={entry.session}
                                        title={entry.createdAt}
                                        content={entry.ROS}
                                        //onOpen={handleEntryOpen}
                                        onDelete={deleteEntry}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

function EntryDetails({ entry, onClose }) {
    return (
        <div className="entry-details">
            <div className="entry-details-header">
                <h2 className="entry-details-title">{entry.title}</h2>
                <button className="entry-details-close" onClick={onClose}>
                    Close
                </button>
            </div>
            <div className="entry-details-content">{entry.content}</div>
        </div>
    );
}

function Entry({ id, title, content, onDelete }) {
    // function handleClick() {
    //     console.log('session: ', id);
    //     onOpen(id);
    // }

    function handleDelete() {
        console.log('delete: ', id);
        if (window.confirm("Are you sure you want to delete this entry? This action cannot be undone.")) {
            onDelete(id);
        }
    }

    function extractNotes() {
        let notes = content["Notes"];

        let txtNotes = ''
        Object.values(notes).forEach(element => {
            txtNotes += '- ' + element + '\n';
        });

        return txtNotes;
    }

    function formatDate(timestamp) {
        const now = new Date();
        const date = new Date(timestamp * 1000);
        const diffTime = Math.abs(now - date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;

        if (diffDays === 0) {
            return date.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric" });
        } else if (diffDays === 1) {
            return "yesterday";
        } else if (diffDays <= 6) {
            return date.toLocaleDateString("en-US", { weekday: "long" });
        } else if (diffDays <= 14) {
            return "last week";
        } else if (date.getFullYear() === now.getFullYear()) {
            return date.toLocaleDateString("en-US", { day: "numeric", month: "short" });
        } else {
            return date.toLocaleDateString("en-US", { day: "numeric", month: "short", year: "numeric" });
        }
    }


    return (
        <div className="entry">
            <div className="entry-header">
                <h2 className="entry-title">{formatDate(title)}</h2>
                <div className="entry-timestamp">{(new Date(title * 1000)).toLocaleString()}</div>
                <button onClick={() => handleDelete(id)} color='red'>
                    <FontAwesomeIcon icon={faTimes} color='red' />
                </button>
            </div>
            <div className="entry-content">
                <div>
                    <br />
                    <p className='entry-notes'>{extractNotes()}</p>
                    <br />
                </div>
                {content["Cheif Complaint"] != "Unknown" ? <div className="form-row">
                    <label htmlFor="chief-complaint">Chief Complaint:</label>
                    <input
                        type="text"
                        id="chief-complaint"
                        name="chief-complaint"
                        value={content["Cheif Complaint"] ?? "None"}
                        readOnly
                    />
                </div> : <></>}
                {content["Character"] != "Unknown" ? <div className="form-row">
                    <label htmlFor="character">Character:</label>
                    <input
                        type="text"
                        id="character"
                        name="character"
                        value={content["Character"] ?? "None"}
                        readOnly
                    />
                </div> : <></>}
                {content["Associated Symptoms"] != "Unknown" ? <div className="form-row">
                    <label htmlFor="associated-symptoms" className="form-label-right">Associated Symptoms:</label>
                    <textarea id="associated-symptoms" name="associated-symptoms" rows="3" value={content["Associated Symptoms"] ?? "None"} readOnly />
                </div> : <></>}
                {content["Onset"] != "Unknown" ? <div className="form-row">
                    <label htmlFor="onset">Onset:</label>
                    <input type="text" id="onset" name="onset" value={content["Onset"] ?? "None"} readOnly />
                </div> : <></>}
                {content["Severity"] != "Unknown" ? <div className="form-row">
                    <label htmlFor="severity">Severity:</label>
                    <input type="text" id="severity" name="severity" value={content["Severity"] ?? "None"} readOnly />
                </div> : <></>}
            </div>
        </div>
    );
}