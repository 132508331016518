import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Footer from '../Components/Footer/Footer'; // Import the Footer component

import '../css/aboutUs.css';

import firebase from '../firebase';
const analytics = firebase.analytics();

export default function AboutUs() {

    analytics.logEvent('View_About_Us')

    return (
        <div>
            <br /><br /><br /><br /> <br />
            <h1>About Us</h1>
            <span>Ask Eva is a personal health companion powered by the latest AI technology.  Ask EVA aims to provide users with comprehensive and personalized mental, social, and medical aid through our innovative web app.</span>
            <br />
            <span>Using a fine-tuned LLM (GPT-3) model, EVA is capable of understanding user concerns, remembering previous conversations, and providing personalized companionship and basic medical advice. Eventually we hope that EVA will be a a first point of contact between medical professionals and patients, answering routine and simple medical questions while also being able to escalate to a qualified professional when necessary.</span>
            <br />
            <span>We understand the importance of having a reliable source of health information and support, which is why we created Ask Eva. Ask EVA aims to eliminates the need to endlessly scroll through Google and WebMD, only to scare oneself with a self-diagnosed rare life-threatening illness. With Ask Eva, users can have peace of mind knowing they have the latest medical information at their fingertips.</span>
            <br />
            <span>Our team is passionate about healthcare innovation and the potential of AI technology to transform the industry. We invite you to join us on this journey and experience the benefits of having a personal health companion in your pocket. If you have any questions or would like to work with us, please don't hesitate to reach out to us at: askeva@yaswedo.com</span>
            <br /><br /><br /><br />
            <div className="wrapper">
                <div className="column">
                    <h2>Examples</h2>
                    <ul className="list">
                        <ul>
                            <li>
                                <button>"Can you write me email for my doctor describing my symptoms?"</button>
                            </li>
                            <li>
                                <button>"Can you ßrecommend any dietary changes or supplements that may help manage my symptoms?"</button>
                            </li>
                            <li>
                                <button>"I just started a new medication and feeling weird. Can you help me understand what's going on?"</button>
                            </li>
                        </ul>
                    </ul>
                </div>
                <div className="column">
                    <h2>Capabilties</h2>
                    <ul className="list">
                        <ul>
                            <li>
                                <button>Get information about your medications and symptoms for ease of mind.</button>
                            </li>
                            <li>
                                <button>Write texts, emails about your symtoms for review by healthcare professionals.</button>
                            </li>
                            <li>
                                <button>A comforting nurse to provide emotional support when you need someone to talk to.</button>
                            </li>
                        </ul>
                    </ul>
                </div>
            </div>
            <br /><br /><br /><br />
            <div>
                <h1>Disclaimer</h1>
                <span>Ask Eva is NOT intended to provide medical advice, or replace the advice of a licensed healthcare professional. Nor should the information from Ask Eva be used to try to determine the presence, absence or severity of an illness or medical condition which a user may be perceiving or experiencing.</span>
                <br /><br />
                <span>The content provided by Ask Eva is for informational purposes ONLY and should not be used as a substitute for professional medical advice, diagnosis, or treatment. </span>
                <br /><br />
                <span>Ask Eva does not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned. Reliance on any information provided by Ask Eva is solely at your own risk.</span>
            </div>
            <br /><br /><br /><br />
            <Footer/>
        </div>
    )
}
