import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import { LinearScale, CategoryScale, BarController, BarElement } from 'chart.js';

Chart.register(LinearScale, CategoryScale, BarController, BarElement);

const JournalGraph = ({ entries }) => {
    if (entries.length === 0) return null;

    const dates = entries.map((entry) => {
        const date = new Date(entry.createdAt * 1000);
        return date.toLocaleDateString();
    });

    const uniqueDates = [...new Set(dates)].sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
    });

    const entryCount = uniqueDates.map((date) => {
        return dates.filter((d) => d === date).length;
    });

    const data = {
        labels: uniqueDates,
        datasets: [
            {
                label: 'Number of Entries',
                data: entryCount,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Number of Journals per Day',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                precision: 0,
            },
        },
    };

    return (
        <div>
            <Bar data={data} options={options} />
        </div>
    );
};

export default JournalGraph;
