import React, { useRef, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";
import './App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

import Carousel from 'react-bootstrap/Carousel';

import firebase from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import LoginPopup from './Components/LoginPopup/LoginPopup';
import Footer from './Components/Footer/Footer'; // Import the Footer component

import UserProfile from './pages/UserProfile';
import ChatRoom from './pages/ChatRoom';
import AboutUs from './pages/About';
import Journal from './pages/Journal';
import { SessionContext } from './sessionContext';
import { getAuth, signInAnonymously } from 'firebase/auth';
import TOU from './pages/TOU';
import Privacy from './pages/Privacy';
import Help from './pages/Help';

const auth = firebase.auth();
const firestore = firebase.firestore()
const analytics = firebase.analytics();

function App() {
  const [session, setSession] = useState(uuidv4());
  const [selectedOption, setSelectedOption] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  useEffect(() => {
    const isMobileDevice = /Mobi/.test(navigator.userAgent); // Check if the user agent contains "Mobi"
    setIsMobile(isMobileDevice);
  }, []);

  switch (window.location.pathname) {
    case "/signout":
      if (auth.currentUser) {
        auth.signOut();
        console.log('Signed out user');
      }
      break;
  };

  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    console.log('is loading...')
  } else {
    if (user) {
      console.log('this user', user)
    } else {
      console.log('user not logged in')
      // handleLoginAnon()
    }
  }
  if (error) {
    console.log('error', error)
  }


  function handleLoginClick() {
    setShowLoginPopup(true);
  }

  function handleLoginPopupClose() {
    setShowLoginPopup(false);
  }

  async function handleLoginWithGoogle() {
    const provider = new firebase.firebase.auth.GoogleAuthProvider();
    console.log(provider);
    await auth.signInWithPopup(provider);
    console.log('user signed in, ', user)
    if (user) { //navigate to chat if the user is real
      window.location.href = '/';
    }
  }

  async function handleLoginAnon() {
    const auth = getAuth();
    let user = await signInAnonymously(auth);
    console.log('user signed in, ', user);
    if (user) {
      window.location.href = '/';
    }
  }


  return (
    <Router>
      <div className="App bg-image">
        <div className="modal-container" style={{ display: showLoginPopup ? 'block' : 'none' }}>
          <LoginPopup
            showLoginPopup={showLoginPopup}
            onClose={handleLoginPopupClose}
            onLoginWithGoogle={handleLoginWithGoogle} // Pass the function as a prop
            onLoginAnon={handleLoginAnon}
          />
        </div>
        <div className="page-container">
          <header>
            <Link href="/" className="no-decoration">
              <h1>Ask EVA</h1>
            </Link>
            <div className="dropdown">
              {window.location.pathname === '/' && user === null && (
                <button className='dowpdown-login' onClick={handleLoginClick}>Talk to EVA</button>
              )}
              <button className="dropdown-toggle" type="button" id="dropdownMenuButton" onClick={() => setSelectedOption(selectedOption === null ? 'profile' : null)}>
                <FontAwesomeIcon icon={faBars} />
              </button>
              <div className={`dropdown-menu${selectedOption !== null ? ' show' : ''}`} aria-labelledby="dropdownMenuButton">
                <Link to="/" className="dropdown-item">Home</Link>
                {user !== null && (
                  <Link to="/profile" className="dropdown-item">Profile</Link>
                )}
                {user !== null && (
                  <Link to="/journal" className="dropdown-item">EVA's Journal</Link>
                )}
                <Link to="/About" className="dropdown-item">About</Link>
                <Link to="/TOU" className="dropdown-item">Terms of Use</Link>
                <Link to='/Privacy' className='dropdown-item'>Privacy Policy</Link>
                <Link to='/Help' className='dropdown-item'>Help</Link>
                {user !== null && (
                  <button className='dropdown-item' onClick={SignOut}>Sign Out</button>
                  // <a href="/signout" className="dropdown-item" onClick="SignOut()">Sign Out</a>
                )}
              </div>
            </div>
          </header>
          <section>
            <SessionContext.Provider value={session}>
              <Routes>
                <Route path='/' element={user ? <ChatRoom /> : <SignIn isMobile={isMobile} />} />
                <Route path='/profile' element={user ? <UserProfile /> : <SignIn isMobile={isMobile} />} />
                <Route path='/journal' element={user ? <Journal /> : <SignIn isMobile={isMobile} />} />
                <Route path='/About' element={<AboutUs />} />
                <Route path='/TOU' element={<TOU />} />
                <Route path='/Privacy' element={<Privacy />} />
                <Route path='/Help' element={<Help />} />
              </Routes>
            </SessionContext.Provider>
          </section>
        </div>
      </div>
    </Router>
  );
}



function SignIn(props) {
  const { isMobile } = props;

  return (
    <>
      <h1>Welcome to Ask EVA</h1>
      <br /><br /><br />
      <div className="video-player-wrapper">
        <video className="video-player" controls>
          <source src="https://firebasestorage.googleapis.com/v0/b/healthgpt-ecea0.appspot.com/o/public%2FAskEVA%20Full.mp4?alt=media&token=47189117-9d8f-481e-afd3-8525cbadd969" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <br /><br />
      <div className='landing-div' id='login'>
        <span>Discover AskEVA! Personalized healthcare support at your fingertips. Track symptoms, manage meds, track test results, and elevate your well-being journey. Join us for a healthier you!</span>
        <br /><br />
      </div>
      <div className="button-container">
        <a href="https://apps.apple.com/us/app/ask-eva/id6456941841" className="download-button ios-button"><img src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png" alt="Download on the App Store" /></a>
        <a href="https://play.google.com/store/apps/details?id=com.tambool.askEVA" className="download-button android-button"><img src="https://lh3.googleusercontent.com/q1k2l5CwMV31JdDXcpN4Ey7O43PxnjAuZBTmcHEwQxVuv_2wCE2gAAQMWxwNUC2FYEOnYgFPOpw6kmHJWuEGeIBLTj9CuxcOEeU8UXyzWJq4NJM3lg=s0" alt="Download on the Google Play Store" /></a>
      </div>
      <br /><br />
      <div className="wrapper">
        <div className="column">
          <h2>Examples</h2>
          <ul className="list">
            <ul>
              <li>
                <button>"Can you write me email for my doctor describing my symptoms?"</button>
              </li>
              <li>
                <button>"Can you recommend any dietary changes or supplements that may help manage my symptoms?"</button>
              </li>
              <li>
                <button>"I just started a new medication and feeling weird. Can you help me understand what's going on?"</button>
              </li>
            </ul>
          </ul>
        </div>
        <div className="column">
          <h2>Capabilties</h2>
          <ul className="list">
            <ul>
              <li>
                <button>Get information about your medications and symptoms for ease of mind.</button>
              </li>
              <li>
                <button>Write texts, emails about your symtoms for review by healthcare professionals.</button>
              </li>
              <li>
                <button>A comforting nurse to provide emotional support when you need someone to talk to.</button>
              </li>
            </ul>
          </ul>
        </div>
      </div>
      <br /><br /><br />
      <div className='landing-div' id="login">
        <span>EVA utilizes the power of ChatGPT to deliver compassionate care and companionship. EVA integrates in-home medical, mental health, and social care, providing a one-stop-shop for all your healthcare questions. Our vision is to provide meaningful companionship and guidance to every user, ensuring they receive the care they deserve. Whether you need assistance with medical treatments or simply want someone to talk to, EVA is here to support you every step of the way.</span>
      </div>

      {/* <button className="sign-in" onClick={signInWithGoogle}>Sign in with Google</button> */}
      <br /><br /><br />
      <Footer />
    </>
  )

}

function SignOut() {
  if (auth.currentUser) {
    auth.signOut();
    console.log('Signed out user');
    window.location.href = '/'
  }
}

export default App;