import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Footer from '../Components/Footer/Footer'; // Import the Footer component

import '../css/privacy.css';

import firebase from '../firebase';
const analytics = firebase.analytics();

export default function Help() {

    analytics.logEvent('View_Help')

    return (
        <div className='left-aligned-text'>
            <br /> <br /> <br /> <br /> 
            <h1>How do I delete my acount?</h1>
            <h3>To close your account permanently:</h3>

            <h5>(1) Sign into the Ask EVA app</h5>
            <h5>(2) Tap the profile icon on the far right of the bottom navigation bar</h5>
            <h5>(3) Tap Delete Account</h5>
            <h5>(4) Yes</h5>

            <h4>Simply removing, uninstalling or deleting the Hinge app from your phone will not close your account entirely. To close your profile, you must follow the above steps.</h4>

            <h4>Please note:  Removing or uninstalling the app from your phone does not delete your account. You must follow the steps above to delete your account.</h4>
            
            <br /> <br /> 
            <h1>How do I clear EVA's memory?</h1>
            <h3>To delete your conversation history with EVA permanently:</h3>

            <h5>(1) Sign into the Ask EVA app</h5>
            <h5>(2) Tap the profile icon on the far right of the bottom navigation bar</h5>
            <h5>(3) Tap Clear Conversations</h5>
            <h5>(4) Yes</h5>

            <br /> <br /> 
            <h1>How do I delete a journal entry?</h1>
            <h3>To a journal entry permanently permanently:</h3>

            <h5>(1) Sign into the Ask EVA app</h5>
            <h5>(2) Tap the journal icon on the bottom navigation bar</h5>
            <h5>(3) Scroll to the journal entry you want to delete and tap the edit journal icon on the top right of the journal entry</h5>
            <h5>(4) Scroll to the bottom of the edit journal entry page</h5>
            <h5>(5) Tap the Delete</h5>
            <h5>(6) Delete</h5>

            <Footer />
        </div>
    )
}
