'use strict'

const { jsPDF } = require('jspdf');
require('jspdf-autotable');

function generatePDF(entries, userInfo) {
    const doc = new jsPDF();

    // User information
    const { name, diagnosis, activePrescriptions } = userInfo;
    const formattedDate = new Date().toLocaleDateString();

    // Add user information to the PDF
    doc.setFontSize(12);
    doc.text(`Name: ${name}`, 20, 20);
    doc.text(`Date: ${formattedDate}`, 20, 30);
    doc.text(`Diagnosis: ${diagnosis ?? 'None'}`, 20, 40);
    doc.text(`Prescriptions: ${activePrescriptions ?? 'None'}`, 20, 50);

    // Calculate the height of the text
    const textHeight = doc.getTextDimensions(`Prescriptions: ${activePrescriptions}`).h;

    // Define table column headers
    const headers = ['Date', 'Time', 'Chief Complaint', 'Character', 'Associated Symptoms', 'Onset', 'Severity'];

    // Prepare table data
    const tableData = entries.map((entry) => {
        const content = entry.ROS;
        const formattedDate = new Date(entry.createdAt * 1000).toLocaleDateString();
        const formattedTime = new Date(entry.createdAt * 1000).toLocaleTimeString();
        return [
            formattedDate,
            formattedTime,
            content["Chief Complaint"] || "",
            content["Character"] || "",
            content["Associated Symptoms"] || "",
            content["Onset"] || "",
            content["Severity"] || "",
        ];
    });

    // Add the table
    const startY = 70 + textHeight; // Adjust startY to create space for the text
    doc.autoTable({
        head: [headers],
        body: tableData,
        startY: startY,
        theme: 'grid',
        styles: {
            cellPadding: 5,
            fontSize: 10,
            font: 'helvetica',
            fontStyle: 'normal',
            halign: 'left',
            valign: 'middle',
            fillColor: '#f3f3f3',
            textColor: '#333333',
        },
        headerStyles: {
            fillColor: '#e0e0e0',
            textColor: '#333333',
            fontStyle: 'bold',
        },
        alternateRowStyles: {
            fillColor: '#f8f8f8',
        },
    });

    return doc;
}

module.exports = { generatePDF };
