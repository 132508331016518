import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Footer from '../Components/Footer/Footer'; // Import the Footer component

import '../css/privacy.css';

import firebase from '../firebase';
const analytics = firebase.analytics();

export default function Privacy() {

    analytics.logEvent('View_Privacy')

    return (
        <div className='left-aligned-text'>
            <br /> <br /> <br /> <br /> <br />
            <h1>Privacy Policy</h1>
            <h5>Introduction</h5>
            <span>We at Tambool L.L.C. ("we," "our," or "us") are committed to protecting your privacy and safeguarding your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our website and mobile app (collectively referred to as "Services"). By using our Services, you agree to the terms and practices outlined in this Privacy Policy.</span>

            <span>Please note that this Privacy Policy does not apply to content processed on behalf of our business customers using our API. The use of such data is governed by separate agreements covering access and use of our API.</span>

            <h5>1. Personal Information We Collect</h5>
            <span>We collect personal information ("Personal Information") in the following ways:</span>

            <span>Personal Information You Provide:</span>
            <ul>
                <li>Account Information: When you create an account, we collect information such as your name, contact details, account credentials, payment card information, and transaction history ("Account Information").</li>
                <li>User Content: Your interactions with our Services, including the information you input, file uploads, or feedback you provide ("Content").</li>
                <li>Communication Information: If you communicate with us, we collect your name, contact information, and the content of your messages ("Communication Information").</li>
                <li>Social Media Information: When you interact with our social media pages, we collect Personal Information you choose to provide, such as contact details ("Social Information").</li>
            </ul>

            <span>Personal Information We Receive Automatically:</span>
            <ul>
                <li>Log Data: Information your browser sends automatically when using our Services, including your IP address, browser type, date and time of requests, and interactions with our website.</li>
                <li>Usage Data: Information about how you use our Services, including content viewed or engaged with, features used, actions taken, time zone, country, dates and times of access, user agent, device type, and connection details.</li>
                <li>Device Information: Device-specific information, including device name, operating system, device identifiers, and browser information.</li>
                <li>Cookies: We use cookies to operate and improve our Services. You can manage your cookie preferences through your browser settings.</li>
            </ul>

            <h5>2. How We Use Personal Information</h5>
            <span>We may use Personal Information for the following purposes:</span>
            <ul>
                <li>Provide, administer, maintain, and analyze the Services to enhance your experience.</li>
                <li>Improve our Services through research and development efforts.</li>
                <li>Communicate with you regarding important updates, service notifications, and promotional offers.</li>
                <li>Develop new programs and services to better serve you.</li>
                <li>Prevent fraud, criminal activity, or misuse of our Services, and protect our IT systems, architecture, and networks.</li>
                <li>Carry out business transfers such as mergers, acquisitions, or transitions of service to other providers.</li>
                <li>Comply with legal obligations and processes, and protect our rights, privacy, safety, property, or that of our affiliates, you, or other third parties.</li>
            </ul>

            <span>Aggregated or De-Identified Information: We may aggregate or de-identify Personal Information for analysis, research, and similar purposes. Such information will no longer identify you personally.</span>

            <h5>3. Disclosure of Personal Information</h5>
            <span>In certain circumstances, we may disclose your Personal Information to third parties without further notice to you, unless required by law:</span>
            <ul>
                <li>Vendors and Service Providers: We share Personal Information with vendors and service providers who assist in our business operations.</li>
                <li>Business Transfers: In the event of strategic transactions, your Personal Information may be disclosed to counterparties and others assisting in the transaction.</li>
                <li>Legal Requirements: We may share your Personal Information with authorities, industry peers, or other third parties as required by law or to protect our rights, safety, and property.</li>
                <li>Affiliates: We may share Personal Information with our affiliates in accordance with this Privacy Policy.</li>
            </ul>

            <h5>4. Your Rights</h5>
            <span>Depending on your location, you may have rights regarding your Personal Information, such as:</span>
            <ul>
                <li>Access: You can request information about our processing of your Personal Information.</li>
                <li>Deletion: You can request the deletion of your Personal Information.</li>
                <li>Correction: You can request corrections to your Personal Information.</li>
                <li>Data Portability: You can request the transfer of your Personal Information to a third party.</li>
                <li>Restriction: You can request restrictions on how we process your Personal Information.</li>
                <li>Withdraw Consent: You can withdraw consent for processing Personal Information when consent is the legal basis for processing.</li>
                <li>Objection: You can object to our processing of your Personal Information.</li>
                <li>Complaints: You can lodge a complaint with your local data protection authority.</li>
            </ul>

            <span>You can exercise these rights through your Ask EVA account or by contacting us at [insert contact details].</span>

            <h5>5. Children</h5>
            <span>Our Services are not intended for children under the age of 13. We do not knowingly collect Personal Information from children under 13. If you believe a child under 13 has provided Personal Information, please contact us.</span>

            <h5>6. Links to Other Websites</h5>
            <span>Our Services may contain links to third-party websites. Your interactions with these websites are subject to their respective privacy policies and terms of service.</span>

            <h5>7. Security and Retention</h5>
            <span>We implement measures to protect Personal Information, but no method of transmission or storage is completely secure. We retain your Personal Information for legitimate business purposes and as required by law.</span>

            <h5>8. International Users</h5>
            <span>By using our Services, you understand and agree that your Personal Information may be processed and stored in the United States and disclosed to service providers in other jurisdictions.</span>

            <h5>9. Changes to the Privacy Policy</h5>
            <span>We may update this Privacy Policy and will post the updated version on our website. Please check for updates periodically.</span>

            <h5>10. How to Contact Us</h5>
            <span>If you have questions or concerns about this Privacy Policy, please contact us at askeva@yaswedo.com.</span>
            <br /> <br />
            <Footer/>
        </div>
    )
}
