import React, { useState, useEffect, useRef, CSSProperties, useContext } from 'react';
import BeatLoader from "react-spinners/BeatLoader";

import axios from 'axios';
import * as ReactBootStrap from 'react-bootstrap';

import { useSpeechSynthesis } from 'react-speech-kit';

import firebase from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';

import flower from '../images/flower.png';
import bot from '../images/boticon.png';

import '../css/chatRoom.css';
import { SessionContext } from '../sessionContext';

const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics();

var dummy;
var isNew = true;

export default function ChatRoom() {
  dummy = useRef();
  const { uid, photoURL } = auth.currentUser;
  const messagesRef = firestore.collection('messages');
  //const query = messagesRef.where('uid', '==', uid).orderBy('createdAt').limit(25);
  const query = messagesRef.where('uid', '==', uid).orderBy('createdAt');

  let [messages] = useCollectionData(query, { idField: 'id' });
  //messages = messages.reverse();

  const session = useContext(SessionContext);

  const [formValue, setFormValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [muted, setMuted] = useState(true);

  useEffect(() => {
    console.log("messages updated", isNew)

    if (isNew) {
      initialize();
    }

    isNew = false;

    dummy.current.scrollIntoView({
      behavior: "smooth"
    })

    console.log('isMuted', muted);
    if(!muted) {
      //play the last message
      tts();
    }
  }, [messages])

  useEffect(() => {
    //console.log(dummy);
    if (isLoading == true) {
      dummy.innerText = "Waiting for Eva to respond... ";
      analytics.logEvent('Waiting_For_Response');
    } else {
      analytics.logEvent('Response_Received');
      dummy.innerText = "End of Conversation"
    }
  }, [isLoading])

  useEffect(() => {
    if (muted == false) {
      console.log('start playing audio');
      tts();
    } else {
      console.log('dont play audio');
    }
  }, [muted])

  async function initialize() {
    console.log('Initialized: ', isNew);
    analytics.logEvent('New_Session');
    await sendMessage(undefined, true);
  }

  const handleMuteToggle = () => {
    console.log('toggle isMuted: ', muted);
    speechSynthesis.cancel();
    setMuted(!muted); //there is how we will play tts audio
    analytics.logEvent(`tap_mic_mute-${muted}`);
  };

  function tts() {
    if(messages.length < 1) {
      return;
    }

    const { text, speaker, photoURL } = messages[messages.length - 1];
    const words = text.split('GMT - ')[1];

    if(speaker === "USER") {
      return;
    }

    console.log('Playing audio');
    // Create a new SpeechSynthesisUtterance object
    const utterance = new SpeechSynthesisUtterance(words);

    // Set the rate to 0.8 (80% of normal speed)
    utterance.rate = 0.8;

    // Speak the text
    speechSynthesis.speak(utterance);
  }

  const sendMessage = async (e, isNew = false) => {
    if (e && isNew == false) {
      e.preventDefault();
    }

    if (!isNew) {
      analytics.logEvent("Send_Message");
    }

    //send api call to server
    const token = await auth.currentUser.getIdToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    }

    let urlString = `${process.env.REACT_APP_URL}/chat`  ;
    console.log('env ', urlString);

    console.log('url: ', urlString);
    console.log('session: ', session);
    console.log('isNew: ', isNew);

    axios.post(urlString, { 'uid': uid, 'session': session, "message": formValue, "isNew": isNew }, config)
      .then(response => {
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        console.log(error);
      })

    setFormValue('');
    setLoading(true);
  }

  return (<>
    <main>
      {messages && messages.map(msg => <ChatMessage key={msg.uuid} message={msg} />)}
      <div ref={dummy} className='endBar'>
        <span>End of Conversation</span>
        <button onClick={handleMuteToggle}>
          <FontAwesomeIcon icon={muted ? faVolumeMute : faVolumeUp} />
        </button>
      </div>

      <BeatLoader
        color="#000000"
        loading={isLoading}
        size={15}
      />
    </main>

    <form className='mobile-form' onSubmit={sendMessage}>
      <textarea className='mobile-textarea' value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="message" />

      <button className='mobile-send-button' type="submit" color={isLoading ? '#737373' : '#009933'} disabled={!formValue || isLoading}>
        {isLoading ? <ReactBootStrap.Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </ReactBootStrap.Spinner> : 'Send'}
      </button>
    </form>
  </>)
}


function ChatMessage(props) {
  const { text, speaker, photoURL } = props.message;
  const messageClass = speaker === "USER" ? 'sent' : 'received';
  const words = text.split('GMT - ')[1];

  return (<>
    <div className={`message ${messageClass}`}>
      <img src={messageClass == 'sent' ? flower : bot} />
      <p>{words}</p>
    </div>
  </>)
}