import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import firebase from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import axios from 'axios';

import Footer from '../Components/Footer/Footer'; // Import the Footer component

import '../css/userProfile.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics();

export default function UserProfile() {
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [prescriptions, setPrescriptions] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [notes, setNotes] = useState('');
  const [user] = useAuthState(auth);

  const userQuery = firestore.collection('users').doc(user.uid);

  useEffect(() => {
    console.log('render');
    getUserInfo();
  }, [])

  //getUserInfo(user);

  const handleSubmit = () => {
    analytics.logEvent('Update_User_Info');
    saveUserInfo();
  };

  function handleNotification(success) {
    console.log('Showing Notification... ', success)
    if (success) {
      toast.success('Profile Information Updated', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error('Unable to update user profile.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  function handleConvoNotificaton(success) {
    console.log('Showing Notification... ', success)
    if (success) {
      toast.success('Conversation history cleared', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error('Unable to clear conversation history', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  function getUserInfo() {
    console.log('Get user info');

    userQuery.get().then((snapshot) => {
      if (!snapshot.exists) {
        console.log('No user data found');
        return
      }

      const info = snapshot.data();
      setName(info.name);
      setAge(info.age);
      setPrescriptions(info.activePrescriptions);
      setDiagnosis(info.diagnosis);
      setNotes(info.notes);

      console.log('Loaded user info from DB');
    })
  }

  function saveUserInfo() {
    userQuery.update({
      name,
      age,
      'activePrescriptions': prescriptions,
      diagnosis,
      notes
    }).then(() => {
      console.log('Updated user data');
      handleNotification(true);
    }).catch((error) => {
      console.log(error)
      handleNotification(false);
    })
  }

  async function clearConversationHistory() {
    let urlString = `${process.env.REACT_APP_URL}/clear`;

    console.log(urlString);

    const uid = auth.currentUser.uid;
    const token = await auth.currentUser.getIdToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    }

    return axios.post(urlString, { 'uid': uid }, config)
      .then(response => {
        console.log('cleared');
        handleConvoNotificaton(true);
      }).catch((error) => {
        console.log(error);
        handleConvoNotificaton(false);
      })
  }

  return (<>
    <ToastContainer />
    <h1 className="Title">Edit Profile</h1>
    <div className="Wrapper">
      <div className="FormGroup">
        <label className="Label">Name</label>
        <input className="Input" type="text" value={name ?? ' '} onChange={(e) => setName(e.target.value)} />
      </div>
      <div className="FormGroup">
        <label className="Label">Age</label>
        <input className="Input" type="number" value={age ?? 0} onChange={(e) => setAge(e.target.value)} />
      </div>
      <div className="FormGroup">
        <label className="Label">Prescriptions</label>
        <textarea className="Textarea" rows="4" value={prescriptions ?? ''} onChange={(e) => setPrescriptions(e.target.value)} />
      </div>
      <div className="FormGroup">
        <label className="Label">Diagnosis</label>
        <textarea className="Textarea" rows="4" value={diagnosis ?? ''} onChange={(e) => setDiagnosis(e.target.value)} />
      </div>
      {/* <div className="FormGroup">
          <label className="Label">Notes</label>
          <textarea className="Textarea" rows="4" value={notes} onChange={(e) => setNotes(e.target.value)} />
        </div> */}
      <div className="ButtonContainer">
        <button className='ClearButton' onClick={() => {
          const confirmed = window.confirm("Are you sure you want to clear the conversation history? This action cannot be reversed.");
          if (confirmed) {
            // Clear conversation history
            clearConversationHistory();
          }
        }}>Clear Conversation History</button>
        <br />
        <button className="SubmitButton" type="save" onClick={handleSubmit}>Save</button>
        <br/><br/>
      </div>
    </div>
    <Footer />
  </>
  )
}
